import type { AnalyticsMessage, AppHeight, DataForParent, NavOptions, NavOptionsMessage } from '../types/messages'
import { getMessageFromIframe, removeMessageFromIframe } from './infrastructure'
import { postGenericMessage } from './messages'

const MARGIN_TOP_BOTTOM_PX = 20

function resizeIframeHeight(iframe, height, unit) {
  const iframeEl = document.querySelector(`#${iframe.id}`)

  // @ts-ignore: Comprends pas pourquoi cette erreur
  if (iframeEl) iframeEl.style.height = unit === '%' ? `${height}%` : `${height + MARGIN_TOP_BOTTOM_PX}px`
}

function analyticsEvent(data, analyticService) {
  switch (data.analyticsFct) {
    case 'trackEvent':
      analyticService?.trackEvent(data.eventName, data.payload)
      break
    case 'resetEcommerce':
      analyticService?.resetEcommerce()
      break
    case 'flushStoredEvents':
      analyticService?.flushStoredEvents()
      break
  }
}

interface IframeListener {
  iframe: { href: string; id: string }
  callbackForMessages?: (data: DataForParent) => void
  router?: {
    push: (navOptions: NavOptions) => void
    options: any
    back: () => void
    resolve(navOptions: NavOptions): NavOptions
  }
  analyticService?: {
    trackEvent: (eventName: string, payload: Object) => void
    resetEcommerce: () => void
    flushStoredEvents: () => void
  }
}

export function iframeListener({ iframe, callbackForMessages, router, analyticService }: IframeListener) {
  getMessageFromIframe(iframe.href, (data: AppHeight | DataForParent | NavOptionsMessage | AnalyticsMessage) => {
    if ('app' in data) {
      resizeIframeHeight(iframe, data.app.height, data.app.unit)
    }
    if ('message' in data && callbackForMessages) {
      callbackForMessages(data)
    }
    if ('routing' in data && router) {
      if (data.routing.goBack) {
        if (router.options?.history?.state?.back) {
          router.push({ path: router.options.history.state.back })
        } else router.back()
      } else {
        try {
          if (router.resolve(data.routing).name !== 'not-found') router.push(data.routing)
          else if (window.parent?.parentRouter) window.parent?.parentRouter?.push(data.routing)
        } catch (error: any) {
          if (window.parent?.parentRouter) window.parent?.parentRouter?.push(data.routing)
        }
      }
    }
    if ('analyticsFct' in data && analyticService) {
      analyticsEvent(data, analyticService)
    }
  })
}

export function removeIframeListener(iframeHref: string) {
  removeMessageFromIframe(iframeHref)
}

export function emitMessageToChild(microFrontName: string, { message, data = {} }: DataForParent) {
  postGenericMessage(microFrontName, {
    message,
    data,
  })
}
